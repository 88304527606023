<template>
    <div class="">
        <header-page :header="headers"></header-page>

        <v-container class="py-8">
            <v-row>
                <v-col class="lg:ml-6 lg:col-start-2 lg:max-w-2xl">
                    <p class="text-base font-semibold leading-6 text-indigo-500 uppercase">
                        African Content Requirements
                    </p>
                    <h4 class="mt-2 text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
                        Key Information
                    </h4>

                    <v-card class="mt-6 p-4" outlined>
                        <h5 class="text-xl font-bold text-gray-800">Company Ownership</h5>
                        <v-divider></v-divider>
                        <ul class="mt-4 space-y-3 font-medium">
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Non-African: <span class="font-semibold">60%</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">African: <span class="font-semibold">30%</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Local/Country: <span class="font-semibold">10%</span></span>
                            </li>
                        </ul>
                    </v-card>

                    <v-card class="mt-6 p-4" outlined>
                        <h5 class="text-xl font-bold text-gray-800">Employment Statistics</h5>
                        <v-divider></v-divider>
                        <ul class="mt-4 space-y-3 font-medium">
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Total Workforce: <span class="font-semibold">{{ employment['Total workforce'].percentage }}%</span> - Deadline: <span class="font-semibold">{{ employment['Total workforce'].deadline }}</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Top Management: <span class="font-semibold">{{ employment['Top management'].percentage }}%</span> - Deadline: <span class="font-semibold">{{ employment['Top management'].deadline }}</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Managers: <span class="font-semibold">{{ employment['Managers'].percentage }}%</span> - Deadline: <span class="font-semibold">{{ employment['Managers'].deadline }}</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Senior Technical Staff: <span class="font-semibold">{{
                                    employment['Senior technical staff'].percentage }}%</span> - Deadline: <span class="font-semibold">{{ employment['Senior technical staff'].deadline }}</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Senior Admin Staff: <span class="font-semibold">{{ employment['Senior admin staff'].percentage }}%</span> - Deadline: <span class="font-semibold">{{ employment['Senior admin staff'].deadline }}</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Support Staff: <span class="font-semibold">{{ employment['Support staff'].percentage }}%</span> - Deadline: <span class="font-semibold">{{ employment['Support staff'].deadline }}</span></span>
                            </li>
                        </ul>
                    </v-card>

                    <v-card class="mt-6 p-4" outlined>
                        <h5 class="text-xl font-bold text-gray-800">Sub-Contracting Requirements</h5>
                        <v-divider></v-divider>
                        <ul class="mt-4 space-y-3 font-medium">
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Total Supply Level 1: <span class="font-semibold">{{ subcontracting.level1.percentage }}%</span> - Deadline: <span class="font-semibold">{{ subcontracting.level1.deadline }}</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Total Supply Level 2: <span class="font-semibold">{{ subcontracting.level2.percentage }}%</span> - Deadline: <span class="font-semibold">{{ subcontracting.level2.deadline }}</span></span>
                            </li>
                            <li class="flex items-start">
                                <svg class="w-5 h-5 text-[#5692CA]" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
                                </svg>
                                <span class="ml-3">Total Supply Level 3: <span class="font-semibold">{{ subcontracting.level3.percentage }}%</span> - Deadline: <span class="font-semibold">{{ subcontracting.level3.deadline }}</span></span>
                            </li>
                        </ul>
                    </v-card>

                    <v-dialog v-model="dialog" max-width="500px">
                        <v-card>
                            <v-card-title class="text-lg font-bold">Edit Requirements</v-card-title>
                            <v-card-text>
                                <v-text-field v-model="newCountry" label="Country" />
                                <v-text-field v-model="newOwnershipNonAfrican" label="Non-African Ownership (%)"
                                    type="number" />
                                <v-text-field v-model="newEmploymentTotal" label="Total Workforce (%)" type="number" />
                                <!-- Additional fields can be added as needed -->
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="green" text @click="saveChanges">Save</v-btn>
                                <v-btn color="red" text @click="dialog = false">Cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <div v-if="isAdmin" class="mt-4">
                        <v-btn color="blue" @click="editContent">Edit Requirements</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
// mdiVideoInputComponent
import { mdiMagnify, mdiMapMarkerOutline, mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import HeaderSearchPage from '@/views/components/HeaderSearchPage.vue'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import VacanciesItemCard from '@/views/components/vacancies/VacanciesItemCard.vue';
import EmptyData from '@/views/components/utils/EmptyData.vue';
export default {
    components: {
        HeaderSearchPage,
        HeaderPage,
        VacanciesItemCard,
        EmptyData
    },
    data() {
        return {
            headers: {
                titlepage: 'More ressources',
                icon: this.icons.mdiMagnify
            },


            countries: ['Nigeria', 'Kenya', 'South Africa'],
            ownership: {
                nonAfrican: 60,
                african: 30,
                local: 10,
            },
            employment: {
                'Total workforce': { percentage: 60, deadline: '30/06/2023' },
                'Top management': { percentage: 40, deadline: '30/06/2023' },
                'Managers': { percentage: 40, deadline: '30/06/2023' },
                'Senior technical staff': { percentage: 90, deadline: '30/06/2023' },
                'Senior admin staff': { percentage: 90, deadline: '30/06/2023' },
                'Support staff': { percentage: 100, deadline: '30/06/2023' },
            },
            subcontracting: {
                level1: { percentage: 60, deadline: '30/06/2023' },
                level2: { percentage: 40, deadline: '30/06/2023' },
                level3: { percentage: 40, deadline: '30/06/2023' },
            },
            isAdmin: true, // This would normally be determined by authentication
            dialog: false,
            newCountry: '',
            newOwnershipNonAfrican: '',
            newEmploymentTotal: '',
        }

    },
    methods: {
        backToPrevious() {
            // alert(this.$route.params.ids)
            // this.$router.go(-1)
            this.$router.push({ name: 'home' })
        },
        editContent() {
            this.dialog = true;
        },
        saveChanges() {
            // Logic to save changes goes here
            this.dialog = false;
        },
    },
    beforeMount() {
    },
    setup(props) {
        const icons = { mdiClose, mdiMapMarkerOutline, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase, mdiOpenInNew }

        return {
            icons
        }
    }
}
</script>